.deviceConfigView{
    width: 100%;
}
.deviceConfigView > p{text-align: center;}

.addWiFiButtonHolder{
    margin: auto;
}
.addWiFiButtonHolder .addWiFiButton{
    margin: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.addWiFiButtonHolder .addWiFiButton > .btnIcon{
    margin-left: 5%;
}

.theOptionSeparator{
    width: 100%;
    height: 30px;
    position: relative;
}
.theOptionSeparator > .orLine{
    position: absolute;
    top: 50%;
    border: 1px solid silver;
    width: 30%;

}
.theOptionSeparator > .orLine:nth-child(1){
    left: 30%;
    transform: translate(-60%, -50%);
}
.theOptionSeparator > .orLine:nth-child(2){
    right: 30%; transform: translate(60%, -50%);
}
.theOptionSeparator > .orText{
    position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
    width: 30px; height: 30px; padding: 2%; display: flex; border-radius: 50%; font-size: 60%;
    background-color: rgba(220, 220, 220, .5);
}

