body {
    font-family: "Trebuchet MS", Helvetica, sans-serif
}
.cardContent{width: 100%; padding: 1%; border-radius: 10px; overflow: hidden; }
.cardContent .cardContentList{width: 100%; border-radius: 10px; overflow: hidden; }
.component-icon {
    border-radius: 50%;
    padding: 7px;
    height: 18px;
    width: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.component-icon-dark {
    background: var(--ion-color-step-850, #27323e);
    color: var(--ion-item-background, #fff);
}
.topDiv{text-align: center; justify-content: center; overflow-y: auto;}
.myHead{position: fixed;}
.ionMyItem{padding: 0%; width: 80%; margin-left: 9%}
.theLabel{text-align: center; width: 70%} 
.iconPart{margin-left:0%; margin-bottom:0%; bottom:0%}
.theInput{margin-left:12%; width: 90%}                               
.myButtons{text-align: center}
.logo{width: 130px; height:130px; border-radius: 12px; left:50%; margin-top: 15%; position: relative; transform: translate(-50%, 0%); border: 1px solid green;}


.criterionMet{
    color: lime;
}