html {background: gainsboro;}
.loaderAfterSplashScreen{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--ion-background-color); */
}
.loaderAfterSplashScreen > .appLogo{
    /* border: 1px solid red; */
    width: 100px;
    height: 100px;
    margin: auto;
}

.navigationTabBarRef{
    display: none;
}

.profileAVTRNoPic{
    position: absolute;
    /* border: 1px solid rgb(77, 255, 0); */
    width: 100%;
    height: 100%;
}
.profileAVTRNoPic > span{
    /* border: 1px solid yellow; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}
.userAVTR{
    position: relative;
    background-color: var(--ion-color-medium-tint);
    color: white;
}
.profileAVTR{
    /* border: 1px solid red; */
    position: relative;
    width: 45px;
    height: 45px; 
    margin-right: 5%;
    background-color: var(--ion-color-medium-tint);
}
.userAVTRNoPic{
    position: absolute;
    width: 100%;
    height: 100%;
}
.userAVTRNoPic > span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 1.3em;
}

.selectedCard{
    padding: 5px;
    position: absolute;
    z-index: 1000;
    right: 0%;
    bottom: -30%;
    min-width: 150px;
    min-height: 75px;
    /* background-color: var(--ion-color-primary-tint); */
    box-shadow: 0px 0px 10px var(--ion-color-primary-tint);
    /* border-radius: 15px; */
    animation: .7s slideInBottomRight cubic-bezier(0.175, 0.885, 0.32, 1.275);
    font-size: 90%;
    color: gainsboro;
}
.selectedCard > .cardHolder{ max-width: 70%; font-weight: bolder;}
.selectedCard > .cardBrand{ max-width: 25%; float: right; font-size: 80%;}
.selectedCard > .cardNumber{ width: 100%;}
.selectedCard > .cardDate{ float: left}
.selectedCard > .cardcvv{ float: right;}