.backgroundDiv{
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  background: rgb(9, 15, 35);
}
/* .forMobile{display: none;} */
.theBackBTN{position: absolute;}
.theBTN{height: 2em; background: transparent;}
.leftSide{display: inline-block; position:fixed; width: 23%; height: 100%; border-right: 1px solid rgb(116, 103, 243);}
.rightSide{display: inline-block; position: fixed; width: 77%; margin-left: 23%; height: 100%}
.headerTop{width: 100%; height: 10%; position: relative; top: 0%;}
.headerTop> h2{ margin-left: 2%; margin-top: 3%; font-weight: bolder;}
.bodyDiv{width: 100%; height: 85%; overflow-y: scroll; overflow-x: hidden; }

.dashNameHolder{width: 85%; margin-left: 7.5%; border-radius: 5px; display: inline-flex;margin-top: 20%; height: 4em;}
.dashNameHolder> .theIMG{height: 100%;display: inline-block;}
.dashNameHolder> .theIMG> img{height: 100%;}
.dashNameHolder> h3{margin-left: 4%; font-weight: bold;}
.sectionHolder{ width: 90%; margin-top: 7%;  margin-left: 5%; --background: transparent !important;}
/* .sectionHolder:nth-child(0){margin-top: 20%;} */
.myCard {
  /* position: relative; */
  /* margin: auto; */
  height: 50%;
  /* margin-top: ; */
  display: inline-block;
  background: rgb(18, 25, 51);
  border: 0.1px solid rgb(116, 103, 243);
  width: 100%;
  max-width: 46%; /* Adjust the maximum width as needed for your design */
}

.chart-container2 {
  position: relative;
  /* margin: auto; */
  height: 45%;
  /* margin-top: ; */
  display: inline-block;
  background: rgb(18, 25, 51);
  border: 0.1px solid rgb(116, 103, 243);
  width: 100%;
  max-width: 95%; 
}

.chart-heading {
  text-align: center;
  /* font-size: 1.5rem;  */
  /* margin-bottom: 20px;  */
}
.myDeviceContainer{width: 21%; display: inline-block; background: rgb(18, 25, 51); border: 1px solid rgb(116, 103, 243);}
.theDeviceHolder{text-align: center; border-bottom: 1px solid;}
.infoHolder{display: flex; justify-content: center; margin-bottom: -5%;}
.infoHolder>p {font-weight: bold; font-size: x-large; }
.infoHolder>.iconPart{margin: 12% 10% 0% 0%; width: 30px; height: 30px;}

ion-modal.modalPop::part(content) {background: rgb(18, 25, 51); border: 1px solid rgb(116, 103, 243);}
ion-modal.modalPop ion-toolbar {--background-color: rgb(9, 15, 35);}

.modalPopHeader-tool{--background: rgb(9, 15, 35); border: 1px solid rgb(116, 103, 243);}
.modalPopContent{--background: rgb(9, 15, 35); border: 1px solid rgb(116, 103, 243);}

@media (max-width: 600px) {
    /* .backgroundDiv{display: none;} */
    /* .forMobile{display: block;} */
  /* .chart-container {
    max-width: 91%; 
  } */
  .myDeviceContainer{width: 40%;}
  .chart-container{width: 100px;}

}

@media (min-width: 960px) and (max-width: 1024px) {
  .myCard{width: 95%; height: 43%; max-width: 95%;}
  .myDeviceContainer{width: 29%;}
  /* .myIcons{display: none;} */
}

@media (min-width: 800px) and (max-width: 960px) {
  .myCard{width: 95%; height: 43%; max-width: 95%;}
  .myDeviceContainer{width: 44%;}
  .infoHolder>.iconPart{margin: 12% 10% 0% 0%; top: -10%}
  .myIcons{display: none;}
}

@media (min-width: 610px) and (max-width: 800px) {
  .myCard{width: 95%; height: 43%; max-width: 95%;}
  .myDeviceContainer{width: 44%;}
  .infoHolder>.iconPart{margin: 12% 10% 0% 0%; top: -10%}
  .myIcons{display: none;}
}

