.subscriptionOptions{
    /* border: 1px solid red; */
    display: flex;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: scale(0.9);
    position: relative;
    border-radius: 20px;
}

.subscriptionOptions .subsOptionCheckPart{
    width: 7%;
    height: 100px;
    float: left;
    position: relative;
    /* border: 1px solid lime; */

}
.subscriptionOptions .subsOptionCheckPart .checkPartIcons{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    font-size: 250%;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50px;
    /* border: 2px solid var(--ion-color-primary-tint); */
}
.subscriptionOptions .subsOptionDetailsPart{
    width: 70%;
    min-height: 100px;
    /* float: right; */
    /* border: 1px solid lime; */
}
.subscriptionOptions .subsOptionpricePart{
    width: 25%;
    height: 100px;
    float: right;
    position: relative;
    /* border: 1px solid lime; */
}
.selectedSubscriptionOptions{
    color: var(--ion-text-color);
    /* background-color: var(--ion-color-primary-tint); */
    /* box-shadow: 0px 0px 25px var(--ion-color-primary-tint); */
    transform: scale(1.0);
}
.subsOptionpricePart .priceAmount{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.selectionComplete{
    border-radius: 25px;
    transition-delay: 1.5s;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.selectionComplete-false{
    width: 50%;
    margin-left: 25%;
}
.selectionComplete-true{
    width: 70%;
    margin-left: 15%;
}
.selectionComplete .welcomeSlideBtnIcons{
    margin-left: 2%;
    transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.selectionComplete-true .welcomeSlideBtnIcons{
    margin-left: 5%;
}
.subscriptOptsHolder{
    /* border: 2px solid red; */
    position: relative;
}

.manageCardsBtn{
    /* float: right; */
    margin: 5%;
}