.qrHolder{
    position: relative;
    width: 100%;
    height: 100%;
    /* margin: auto; */
    margin: 0%;
    top: 0%;
    left: 0%;
    overflow: hidden;
}
.qrHolder > .qrOverlay{
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    /* border: 50px solid rgba(0, 0, 0, .2); */
    z-index: 1000;
}
.qrHolder > video{
    position: absolute;
    z-index: -1;
    /* display: none;  */
    width: inherit; height: inherit;
    top: 0%;
    left: 0%;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    /* border: 2px solid red; */

}
.qrHolder > canvas{
    position: absolute;
    display: none; 
    /* width: inherit; height: inherit; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}