.loginPage{
    transition: 0.3s ease-in-out;
}
.topDiv{overflow-y: auto;}
.pageLabel{text-align: center;}
.theLabel{text-align: left; margin-left: 10%;}
.iconPart{margin-bottom: -11%;}
.theInput{margin-left: 10%; width: 90%;}
.myForgot{text-align: center; margin-bottom: 4%;}
.mySpan{color: var(--ion-color-primary);}
.logo{
    /* border: 1px solid lime;  */
    width: 120px; height: 120px; 
    margin-left: 50%; margin-top: 15%; transform: translate(-50%); 
    border-radius: 25px;
}


.loginMainHolder{
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;
    display: inline-flex;
    position: relative;
}
.loginMainHolder > .otherPageLink{
    display: flex;
    justify-content: center;
    position: absolute; 
    z-index: 1011111111111;
    top: 0%;
    left: 50%;
    font-weight: bolder;
    font-size: 70%;
    transform: translate(-50%, -150%);
    /* border: 1px solid red; */
}
.loginMainHolder > .otherPageLink > a{
    text-decoration: none;
    color: var(--ion-text-color);
    margin: 0% 3% 0% 3%;
    min-width: 65px;
    text-transform: uppercase;
    text-align: center;
    /* border: 1px solid pink; */
}

.loginMainHolder > .loginFormHolder{
    /* border: 1px solid red; */
    display: inline-block;
    width: 100%;
    height: 100%; 
    margin: auto;
}
.loginMainHolder > .loginFormHolder > .formsContainer{
    width: 100%;
    height: 90%;
    margin: 10% 0% 0% 0%;
    /* border: 1px solid lime; */
}

@media only screen and (min-width: 600px) {
    .loginMainHolder{
        width: 100%;
        height: 100%;
        display: inline-flex;
    }
    .loginMainHolder > .otherPageLink{
        display: flex;
        justify-content: center;
    }
    .loginMainHolder > .loginFormHolder{
        display: inline-block;
        /* width: 30%; */
        width: 350px;
        height: 100%; 
        margin: auto;
    }
    .loginMainHolder > .loginFormHolder > .formsContainer{
        width: 96%;
        height: 88%;
        margin: 10% 2% 2% 2%;
        padding-top: 5%;
        border-radius: 25px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        overflow: hidden;
        box-shadow: 5px 5px 25px rgba(127, 127, 127, 0.3);
    }
}