.insightsGrid{
    display: grid;
    grid-template-columns: auto auto;
    /* border: 1px solid red; */
    padding-left: 3%;
    padding-right: 3%;
}
.insightsGrid .gridItem{
    min-width: 145px;
    height: 145px;
    /* border: 1px solid lime; */
}
.insightsGrid .gridItemLoader:nth-child(2){ opacity: .7; }
.insightsGrid .gridItemLoader:nth-child(3){ opacity: .5; }
.insightsGrid .gridItemLoader:nth-child(4){ opacity: .3; }
.insightsGrid .gridItemLoader .iconPart{opacity: .4; }
.insightsGrid .gridItem .msgPart{margin: 10%;  margin: 10% 0% 0% 10%;}
.insightsGrid .gridItem .msgPart > span{color: var(--ion-color-primary);}
.insightsGrid .gridItem .valuePart{
    margin: 10% 0% 10% 10%; font-weight: bold; font-size: x-large; 
    display: inline-block;
}
.insightsGrid .gridItem .iconPart{margin: 10% 10% 10% 0%; width: 40px; height: 40px; float: right; color: var(--ion-color-primary);}
.insightsGrid .gridItem .unitsPart{ margin: 0% 0% 10% 10%; padding: none; width: 80%; display: flex; flex-direction: row; }